/* eslint-disable import/prefer-default-export */
import {
  DELETE_ORCHESTRATION, DISABLE_ORCHESTRATIONS, FETCH_ALL_EXECUTION_REQUESTS, FETCH_ALL_HISTORICAL_EXECUTION_REQUESTS, FETCH_ALL_ORCHESTRATIONS,
  FETCH_ORCHESTRATION_BY_ID, FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID, SAVE_ORCHESTRATION, UPDATE_ORCHESTRATION,
  UPDATE_ORCHESTRATION_STATUS
} from "redux/constants";
import { URL_ORCHESTRATIONS } from "redux/constants/endpoints";
import { formatDateTimeIsoParam } from "util/index";

export const fetchOrchestrationsList = (filter = {}) => {
  const {
      searchCriteria,
      pageNo,
      pageSize,
      status,
      sortBy,
      showDeleted,
  } = filter;
  return {
      type: FETCH_ALL_ORCHESTRATIONS,
      request: {
          url: encodeURI(`${URL_ORCHESTRATIONS}/${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
          method: "GET",
          params: {
              page: pageNo,
              size: pageSize,
              sort: sortBy,
              status: status ? status.join() : null,
              showHidden: showDeleted,
          },
      },
  };
};

export const fetchOrchestrationById = (id) => ({
    type: FETCH_ORCHESTRATION_BY_ID,
    request: {
      url: `${URL_ORCHESTRATIONS}/${id}`,
      method: "GET",
    },
  });

export const saveOrchestration = (orchestration, onSuccess, onError) => ({
    type: SAVE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}/save`,
      method: "PUT",
      data: orchestration,
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

export const updateOrchestration = (id, orchestration, onSuccess, onError) => ({
    type: UPDATE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}/update/${id}`,
      method: "PUT",
      data: orchestration,
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

export const deleteOrchestrations = (ids, onSuccess, onError) => ({
    type: DELETE_ORCHESTRATION,
    request: {
      url: `${URL_ORCHESTRATIONS}/delete?ids=${ids.join(",")}`,
      method: "DELETE",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess && response.status === 200) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    }
  });

export const disableOrchestrations = (ids) => ({
    type: DISABLE_ORCHESTRATIONS,
    request: {
      url: `${URL_ORCHESTRATIONS}/disable`,
      method: "PUT",
      params: { ids },
    },
  });

export const updateOrchestrationStatus = (id, status, onSuccess, onError) => ({
    type: UPDATE_ORCHESTRATION_STATUS,
    request: {
      url: `${URL_ORCHESTRATIONS}/status/${id}`,
      method: "PUT",
      params: { status },
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess && response.status === 200) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  }
  });

  export const fetchWorkQueuesByOrchestratorId = (id) => ({
    type: FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID,
    request: {
      url: `${URL_ORCHESTRATIONS}/queue/${id}`,
      method: "GET",
    },
  });

export const fetchHistoricalExecutionRequestsList = (
  page,
  size,
  sortField,
  sortOrder,
  processes,
  divisions,
  fleetIds,
  fromDate,
  toDate,
  searchCriteria,
  tags = [],
) => ({
      type: FETCH_ALL_HISTORICAL_EXECUTION_REQUESTS,
      request: {
          url: encodeURI(`${URL_ORCHESTRATIONS}/execution-requests/historical/${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
          method: "GET",
          params: {
            page,
            processesIds: processes.join(),
            divisionsIds: divisions?.join(),
            size,
            sort: `${sortField},${sortOrder}`,
            fleetIds: fleetIds?.join(),
            fromDate: formatDateTimeIsoParam(fromDate),
            toDate: formatDateTimeIsoParam(toDate),
            tagsIds: tags?.join(),
        },
    },
  });

export const fetchExecutionRequestsList = (
  page,
  size,
  sortField,
  sortOrder,
  processes,
  divisions,
  fleetIds,
  fromDate,
  toDate,
  searchCriteria,
  tagsIds = [],
) => ({
  type: FETCH_ALL_EXECUTION_REQUESTS,
  request: {
      url: encodeURI(`${URL_ORCHESTRATIONS}/execution-requests/${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
      params: {
          page,
          processesIds: processes.join(),
          divisionsIds: divisions?.join(),
          size,
          sort: `${sortField},${sortOrder}`,
          fleetIds: fleetIds?.join(),
          fromDate: formatDateTimeIsoParam(fromDate),
          toDate: formatDateTimeIsoParam(toDate),
          tagsIds: tagsIds?.join(),
      },
  },
});
