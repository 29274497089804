import {
    FETCH_ANALYSIS_DATA,
    FETCH_ANALYSIS_TABLE_DATA,
    FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_PROCESS,
    FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_REASON,
    FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TREND,
    FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TYPE,
    FETCH_PROCESSES_KPI,
    FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI,
    UPDATE_BE_SINCE_DATE
} from "redux/constants";
import {
    URL_PROCESS_EXECUTION,
    URL_PROCESSES,
    URL_PROCESSES_KPI,
    URL_ANALYTICS
} from "redux/constants/endpoints";
import { formatDateTimeIsoParam, formatDateParam } from "util";
import { exceptionsEntity } from "util/configs/backendEntities";

export const fetchProcessExceptionsAnalyticsKpiByProcess = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    maxProcesses,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_PROCESS,
    request: {
        url: `${URL_PROCESS_EXECUTION}${exceptionsEntity}/analytics/group-by-process?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}`,
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            fleetIds: fleetIds?.join(","),
            maxProcesses,
            tagsIds,
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const fetchProcessExceptionsAnalyticsKpiByReason = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_REASON,
    request: {
        url: `${URL_PROCESS_EXECUTION}${exceptionsEntity}/analytics/group-by-reason?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}`,
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            fleetIds: fleetIds?.map((c) => c.id).join(","),
            tagsIds,
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const fetchProcessExceptionsAnalyticsKpiByType = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TYPE,
    request: {
        url: `${URL_PROCESS_EXECUTION}${exceptionsEntity}/analytics/group-by-type?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}`,
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            fleetIds: fleetIds?.map((c) => c.id).join(","),
            tagsIds,
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const fetchProcessExceptionsAnalyticsKpiByTrend = (
    processes,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    tagsIds,
    onSuccess,
    onError
) => ({
    type: FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TREND,
    request: {
        url: `${URL_PROCESS_EXECUTION}${exceptionsEntity}/analytics/group-by-trend?processes=${processes}&exceptions=${exceptions}&exceptionsType=${exceptionsType}&hidden=${showHidden}`,
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            fleetIds: fleetIds?.map((c) => c.id).join(","),
            tagsIds
        },
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const fetchProcessesKpi = (
    {
        process, selectedDurationValue: period, fromDate, toDate, division, fleets, tags
    },
    onSuccess,
    onError,
) => ({
    type: FETCH_PROCESSES_KPI,
    request: {
        url: URL_PROCESSES_KPI,
        params: {
            processids: process?.join(),
            divisionids: division?.join(),
            fleetsIds: fleets?.join(),
            period,
            maxProcess: 3,
            from: formatDateTimeIsoParam(fromDate),
            to: formatDateTimeIsoParam(toDate),
            tagsIds: tags?.join()
        },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (err) => {
            if (onError) onError();
            throw err;
        },
    },
});
export const updateProcessKpiParameters = (settings) => ({
    type: UPDATE_BE_SINCE_DATE,
    request: {
        url: `${URL_PROCESSES}kpiParameters`,
        data: settings,
        method: "put",
    },
});
export const fetchTodayUnhandledExceptions = (onSuccess) => ({
    type: FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI,
    request: {
        url: `${URL_PROCESSES_KPI}/today-unhandled-exceptions`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
    },
});
export const fetchAnalysisTableData = (selectedProcesses, from, to, kpi, customKpisIds, tags, exceptionsTypes = [], fleetIds = [], page, size) => {
    const processIds = selectedProcesses.map((e) => e.id);
    const tagsIds = tags?.map((e) => e.id)?.join() ?? "";
    return {
        type: FETCH_ANALYSIS_TABLE_DATA,
        request: {
            url: `${URL_ANALYTICS}table`,
            params: {
                processIds: `${processIds?.join()}`,
                kpi: `${kpi.join()}`,
                from: formatDateParam(from),
                to: formatDateParam(to),
                customKpisIds: `${customKpisIds.join()}`,
                tagsIds,
                exceptionsTypes: exceptionsTypes.join(),
                fleetIds: fleetIds.join(),
                page,
                size
            }
        },
    };
};
export const fetchAnalysisData = (selectedProcesses, from, to, kpi, customKpisIds, tags, exceptionsTypes = [], fleetIds = []) => {
    const processIds = selectedProcesses.map((e) => e.id);
    const tagsIds = tags?.map((e) => e.id)?.join() ?? "";
    return {
        type: FETCH_ANALYSIS_DATA,
        request: {
            url: `${URL_ANALYTICS}`,
            params: {
                processids: `${processIds?.join()}`,
                tagsIds,
                kpi: `${kpi.join()}`,
                from: formatDateParam(from),
                to: formatDateParam(to),
                customKpisIds: `${customKpisIds.join()}`,
                exceptionsTypes: exceptionsTypes.join(),
                fleetIds: fleetIds.join(),
            }
        },
    };
};
