import React, { cloneElement, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import SubPagePanel from "../../../components/SubPagePanel";
import SubPageContainer from "../../../components/SubPageContainer";
import OrchestratorManagement from "../OrchestratorManagement";
import useStyles from "../style";
import { isSubModulePermitted } from "../../../components/HasPermission";
import RobotManagement from "../RobotManagement";
import { isFleetAdministrator } from "util";
import { updateModule } from "../../../redux/slices/adminFilterSlice";
import QueueManagement from "../QueueManagement";
import { isSRQueuesDisabled } from "util/constants/PermitedFeature"
import {
  INTELLIGENT_ORCHESTRATORS_BASE_URL, ORCHESTRATORS_BASE_URL, QUEUES_BASE_URL, RESOURCE_BASE_URL
} from "util/helpers/route.helper";
import OrchestrationManagement from "pages/IntelligentOrchestration";

export default function RobotsAdministration(props) {
    const routes = [
        ORCHESTRATORS_BASE_URL,
        RESOURCE_BASE_URL,
        ...!isSRQueuesDisabled ? [QUEUES_BASE_URL] : [],
        INTELLIGENT_ORCHESTRATORS_BASE_URL
    ];

    const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
    const currModule = useSelector(({ adminFilter }) => adminFilter.currModule);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const value = () => {
        const path = routes.find((element) => history.location.pathname.includes(element));
        return routes.indexOf(path);
    }

    const module = history.location.pathname.split("/").slice(1, 2).join("/");

    useEffect(() => {
        if (module !== currModule) {
            dispatch(updateModule({ module }));
        }
    }, [module]);

    return (
      <SubPageContainer>
        <div className={classes.root}>
          {props?.standAloneModalComponent}
          {(props.showEditModal || props.tableView) && (
              cloneElement(props.modalComponent, { open: props.showEditModal || props.tableView })
          )}
          <Grid className={classes.firstGrid}>
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel value={value()} index={0} classes={classes.noPadding}>
                <OrchestratorManagement />
              </SubPagePanel>
            )}
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel value={value()} index={1} classes={classes.noPadding}>
                <RobotManagement />
              </SubPagePanel>
            )}
            {true && (
            <SubPagePanel
              value={value()}
              index={3}
              classes={classes.noPadding}
            >
              <OrchestrationManagement />
            </SubPagePanel>
              )}
            {(isFleetAdministrator(currentUser)
              || isSubModulePermitted(currentUser, "SR Queues"))
              && !isSRQueuesDisabled && (
                <SubPagePanel
                  value={value()}
                  index={2}
                  classes={classes.noPadding}
                >
                  <QueueManagement />
                </SubPagePanel>
              )}
          </Grid>
        </div>
      </SubPageContainer>
    );
}
