import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Box, Chip, Switch, Typography
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  deleteOrchestrations,
  updateOrchestrationStatus,
} from "../../../../redux/actions/services";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomTableCell from "../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import PermissionManagement from "pages/Administration/PermissionsManagement/PermissionsManagement";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import {
  composeScheduleDescription, days, hours, minutes, months, quartzInitialDays, triggers, weeks, years
} from "pages/IntelligentOrchestration/util";
import useStyles from "./style";

export default function OrchestrationTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("isActive");
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [openDeleteMsgConfirm, setOpenDeleteMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });
  const periods = [
    {
      value: "MINUTELY", label: t("Minutely"), frequency: minutes, frequencyLabel: t("Minute(s)")
     },
    {
      value: "HOURLY", label: t("Hourly"), frequency: hours, frequencyLabel: t("Hour(s)")
    },
    {
      value: "DAILY", label: t("Daily"), frequency: days, frequencyLabel: t("Day(s)")
    },
    {
      value: "WEEKLY", label: t("Weekly"), frequency: weeks, frequencyLabel: t("Week(s)")
    },
    {
      value: "MONTHLY", label: t("Monthly"), frequency: months, frequencyLabel: t("Month(s)")
    },
    {
      value: "YEARLY", label: t("Yearly"), frequency: years, frequencyLabel: t("Year(s)")
    },
  ]

  const dayOrder = [
    { value: 1, label: t("First") },
    { value: 2, label: t("Second") },
    { value: 3, label: t("Third") },
    { value: 4, label: t("Fourth") },
    { value: 5, label: t("Last") },
  ];

  const scheduleTypeDayOfMonth = [
    "NTH_DAY_OF_MONTH",
    "SPECIFIC_DAYS_OF_WEEK_IN_MONTH",
    "LAST_DAY_OF_MONTH"
  ];

  const renderCustomTriggerDetails = () => {
    const trigger = getFieldData("trigger");
    if (trigger === triggers[0]) {
      return (
        <Grid container alignItems="center" direction="row" wrap="nowrap">
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle}>
              {`${t("Queue name")}: `}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitleQueueName}>
              {getFieldData("workQueueName")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle}>
              {` ${t("using.slas")} :`}
            </Typography>
          </Grid>
          <Grid item>
            <Box width="fit-content">
              <Chip
                label={getFieldData("orchestrationSlas")?.map((sla) => sla?.name).join(", ")}
                className={classes.tagChip}
                classes={{ label: classes.tagChipLabel }}
              />
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (trigger === triggers[1]) {
      const schedulingInfo = getFieldData("schedulingInfo");
      const selectedDays = quartzInitialDays.map((day) => ({ ...day, checked: schedulingInfo?.allowedDays.includes(day.id) }));
      const selectedDayId = quartzInitialDays.find((d) => d.checked)?.id;
      const startDay = new Date(schedulingInfo?.startDate).getDay();
      return (
        <Grid alignItems="center">
          <Typography variant="subtitle2" className={classes.cellTitle}>
            {composeScheduleDescription(schedulingInfo?.frequency, schedulingInfo?.unitType, selectedDays, periods, schedulingInfo?.executionTime, t, scheduleTypeDayOfMonth, dayOrder, schedulingInfo?.weekOrder, selectedDayId, startDay, schedulingInfo?.scheduleTypeDayOfMonth)}
          </Typography>
        </Grid>
      );
    }
    return "---"
  }

  const displayFields = [
    {
    },
    {
      id: "name",
      valuePath: "name",
    },
    {
      id: "description",
      valuePath: "description",
    },
    {
      id: "automation",
      valuePath: "processName",
    },
    {
      id: "trigger",
      valuePath: "trigger",
      translate: true,
    },
    {
      id: "triggerDetails",
      customContent: renderCustomTriggerDetails(),
    },
    {
      id: "resourceGroup",
      customContent: (
        <Box width="fit-content">
          <Chip
            label={getFieldData("resourceGroupName")}
            className={classes.tagChip}
            classes={{
              label: classes.tagChipLabel
            }}
        />
        </Box>
      )
    },
  ];

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(updateOrchestrationStatus(id, !isOrchestrationActive)).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          snackBarMessageOfOrchestration()
          fetchEntities();
        }
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const cancelDeleteConfirm = () => {
    resetLoaders();
    setOpenDeleteMsgConfirm(false);
  };

  const snackBarMessageOfDeleteOrchestration = () => {
    toast.success(t("orchestration.deleted.success"));
  };

  const snackBarMessageOfOrchestration = () => {
    toast.success(t("orchestration.status.changed.success"));
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const confirmStatusChange = () => {
    handleChangeStatus();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteOrchestrations([toDeleteId])).then((res) => {
      if (res?.status === 200) {
        snackBarMessageOfDeleteOrchestration();
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));
      setOpenDeleteMsgConfirm(false);
      resetLoaders();
      fetchEntities();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const isOrchestrationActive = row?.isActive;

  const generateText = (field) => {
    let text;
    if (field.translate) text = t(getFieldData(field?.valuePath) ?? field.defaultValue);
    if (field.format) text = field.format(getFieldData(field.valuePath));
    else text = getFieldData(field?.valuePath)
    if (field.isPercentageValue) text += "%";
    return text;
  }
  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (
          <CustomTableCell
            key={i}
            title={t(field?.title)}
            text={t(generateText(field))}
            width={field?.width}
          >
            {field.customContent}
          </CustomTableCell>
        ))}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {true && (
              <Grid item xs={2}>
                <CustomEditIcon
                    id="admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
            )}
            {true && (
              <Grid item xs={4}>
                <Tooltip
                  placement="top"
                  title={
                    !isOrchestrationActive
                      ? t("tooltip.action.enable")
                      : t("tooltip.action.disable")
                  }
                >
                  <Switch
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    checked={isOrchestrationActive}
                  />
                </Tooltip>
              </Grid>
            )}
            {true && (
              <Grid item xs={3}>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <CustomDeleteIcon
                    id="tag-admin-delete-btn"
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(get(row, "id"));
                      setOpenDeleteMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  />
                </Tooltip>
              </Grid>
            )}
            {true && (
              <Grid item xs={3}>
                <Checkbox
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(parseInt(getFieldData("id"), 10))
                      : list.splice(
                          list.indexOf(parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("resource.management.disable.confirmMsg")
            : t("resource.management.enable.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("resource.management.disable.button.confirm")
            : t("resource.management.enable.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={t("orchestration.management.delete.confirmMsg")}
        openStart={openDeleteMsgConfirm}
        onCancel={cancelDeleteConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      <ConfirmMessage
        message={active ? t("orchestration.management.disable.confirmMsg") : t("orchestration.management.enable.confirmMsg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmStatusChange}
        buttonConfirm={t("Confirm")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
    </>
  );
}
