import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {
  debounce,
} from "@material-ui/core";
import {
  handleDisplayedLabel,
  formatDatePickerByLanguage, isFleetAdministrator,
} from "util";
import {
 subWeeks, subMonths, subYears, isAfter
} from "date-fns";
import { format } from "date-fns-tz";
import { Duration } from "util/constants";
import ClearFilter from "components/ClearFilter";
import { initFilter, updateFilter } from "redux/slices/executionsSlice";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import DateFilter from "../../../Services/components/DateFilter";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import ShowMoreFilters from "../../../../components/ShowMoreFilters";
import ExportButton from "../../../../components/ExportButton";
import useStyles from "../style";
import { useQuery } from "@redux-requests/react";
import { FETCH_ALL_EXECUTIONS, FETCH_CURRENT_USER } from "../../../../redux/constants";
import PageHeader from "components/PageHeader";
import { SearchFilter } from "components/Filter";

const dateFormat = "yyyy/MM/dd";

const triggerItems = [
  { id: 1, label: "MANUALLY", value: "MANUALLY" },
  { id: 2, label: "SCHEDULED", value: "SCHEDULED" },
  { id: 3, label: "SCHEDULED_ON_COMPLETE", value: "SCHEDULED_ON_COMPLETE" },
  { id: 4, label: "SCHEDULED_ON_EXCEPTION", value: "SCHEDULED_ON_EXCEPTION" }
]

function Filter(props) {
  const {
processes, status, resources, columns, setShowCustomDate, showCustomDate, tags, fleets, executionsCount
} = props;
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const classes = useStyles()();
  const [statusVar, setStatusVar] = React.useState([]);
  const [resource, setResource] = React.useState([]);
  const [process, setProcess] = React.useState([]);
  const [trigger, setTrigger] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);
  const [defaultFilter, setDefaultFilter] = React.useState(true);
  const [selectedDurationValue, setSelectedDurationValue] = React.useState("ALL_TIME");
  const [sort, setSort] = React.useState(columns[0]);
  const [tag, setTag] = useState([]);
  const [fromSelectedDate, setFromDate] = React.useState(
    format(new Date(), dateFormat)
  );
  const [toSelectedDate, setToDate] = React.useState(
    format(new Date(), dateFormat)
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const executionsFilter = useSelector(({ executionsFilter }) => executionsFilter);
  const executionsData = useQuery({ type: FETCH_ALL_EXECUTIONS }).data;
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [futureEndDate, setFutureEndDate] = useState(false)
  const [futureStartDate, setFutureStartDate] = useState(false)
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const [selectedFleet, setSelectedFleet] = useState([])
  const handleChangeSort = (value = columns[1]) => {
    const sortValue = value || columns[1];
    setSort(sortValue);
    props.handleRequestSort(sortValue);
    dispatch(updateFilter({ ...executionsFilter, order: sortValue }));
  }

  const handleChangeStatus = (event) => {
    setStatusVar(event);
    props.handleChangeStatus(event);
    dispatch(updateFilter({ ...executionsFilter, status: event }));
  };

  const handleChangeResource = (event) => {
    setResource(event);
    props.handleChangeResource(event);
    dispatch(updateFilter({ ...executionsFilter, resource: event }));
  };

  const handleChangeTrigger = (newTriggers) => {
    const values = newTriggers?.map(({ value }) => value)
    setTrigger(values);
    props.handleChangeTrigger(values);
    dispatch(updateFilter({ ...executionsFilter, trigger: values }));
  };

  const handleChangeProcess = (event) => {
    setProcess(event);
    props.handleChangeProcess(event);
    dispatch(updateFilter({ ...executionsFilter, process: event }));
  };
  const handleChangeFleet = (event) => {
    setSelectedFleet(event)
    handleChangeProcess([])
    handleChangeResource([])
    handleChangeTagsFilter([])
    const values = event?.map(({ id }) => id);
    dispatch(
        updateFilter({
          ...executionsFilter,
          fleets: event?.map(({ id }) => id),
        }),
    );
    props.handleChangeFleet(values);
  };
  const onSelectDurationValue = (value) => {
    setSelectedDurationValue(value);
    if (value !== "CUSTOM") {
      const now = new Date();
      let toDate = format(now, dateFormat);
      let fromDate;
      switch (value) {
        case Duration.DAY:
          fromDate = toDate;
          break;
        case Duration.WEEK:
          fromDate = format(subWeeks(now, 1), dateFormat);
          break;
        case Duration.MONTH:
          fromDate = format(subMonths(now, 1), dateFormat);
          break;
        case Duration.YEAR:
          fromDate = format(subYears(now, 1), dateFormat);
          break;
        case Duration.ALL_TIME:
          fromDate = null;
          toDate = null;
          break;
        default:
          break;
      }
      setToDate(toDate);
      setFromDate(fromDate);
      setShowCustomDate(false);
      props.handleChangeDates(fromDate, toDate);
      dispatch(updateFilter({
        ...executionsFilter,
        executionToDate: toDate,
        executionFromDate: fromDate,
        executionSelectedDurationValue: value
      }));
    } else {
      const now = new Date();
      const newFromDate = executionsFilter.executionFromDate || format(now, dateFormat);
      const newToDate = executionsFilter.executionToDate || format(now, dateFormat);
      setToDate(newToDate);
      setShowCustomDate(true);
      setFromDate(newFromDate);
      dispatch(updateFilter({
        ...executionsFilter,
        executionSelectedDurationValue: value
      }));
    }
  };
  const onFromDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureStartDate(true)
    } else if (format(date, dateFormat) > toSelectedDate) {
      setInvalidStartDate(true)
    } else {
    setFromDate(date);
    dispatch(updateFilter({ ...executionsFilter, executionFromDate: format(date, dateFormat) }));
    props.handleChangeDates(date, toSelectedDate);
    }
  };
  const onToDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureEndDate(true)
    } else if (format(date, dateFormat) < fromSelectedDate) {
      setInvalidEndDate(true)
    } else {
      setFutureEndDate(false)
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      setToDate(date);
      dispatch(updateFilter({ ...executionsFilter, executionToDate: format(date, dateFormat) }));
      props.handleChangeDates(fromSelectedDate, date);
    }
  };

  const handleFilterValueChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value);
  };
  React.useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  React.useEffect(() => {
    setSearchText(executionsFilter.searchText);
    setProcess(executionsFilter.process);
    setResource(executionsFilter.resource);
    setStatusVar(executionsFilter.status);
    setTrigger(executionsFilter.trigger);
    setSort(executionsFilter.order);
    setTag(executionsFilter.tag)
    setSelectedDurationValue(executionsFilter.executionSelectedDurationValue);
    if (executionsFilter.executionFromDate) {
      setFromDate(executionsFilter.executionFromDate);
    }
    if (executionsFilter.executionToDate) {
      setToDate(executionsFilter.executionToDate);
    }
    if (isInstanceOwner) {
      setSelectedFleet(executionsFilter?.fleets)
    }
    onSelectDurationValue(executionsFilter.executionSelectedDurationValue);
  }, []);

  const debouncer = React.useCallback(
    debounce((nextValue) => {
      dispatch(updateFilter({ ...executionsFilter, searchText: nextValue }));
      props.handleChangeSearchText(nextValue);
    }, 500),
    []
  );
  const clearFilter = () => {
    setStatusVar([]);
    setResource([]);
    setTrigger([]);
    setProcess([]);
    setSelectedDurationValue("ALL_TIME");
    setFromDate(null);
    setTag([]);
    setToDate(null);
    setSearchText("");
    setShowCustomDate(false);
    setSelectedFleet([]);
    // to clear the redux executionsFilter
    dispatch(initFilter());
    setSort(columns[0]);
    props.handleClearFilter();
  };

  const handleDisplayedOptionLabel = (label, keepCaseLabel = false, translateLabel = true) => handleDisplayedLabel(translateLabel ? t(label) : label, keepCaseLabel)

  const handleChangeTagsFilter = (value) => {
    setTag(value);
    props.handleChangetags(value);
    dispatch(updateFilter({ ...executionsFilter, tag: value }));
  };

  return (
    <Grid container>
      <Grid container item justify="space-between" alignItems="center">
        <PageHeader
            title="Executions"
            filteredCount={executionsCount}
        />
        {executionsData?.length > 0 && (
        <Grid container item xs={1} justify="flex-end">
          <ExportButton
                  loading={props.exportLoading}
                  onClick={() => props.exportFilteredExecutions()}
              />
        </Grid>
        )}
      </Grid>
      <Grid container item alignItems="flex-end" spacing={2}>
        <Grid item xs={2}>
          <SearchFilter
            callback={handleFilterValueChange}
            value={searchText}
        />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
            multiple
            options={tags || []}
            optionLabel="name"
            value={tags?.filter(({ id }) => tag?.map(({ id }) => id)?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
            onChange={handleChangeTagsFilter}
            label={t("Tags")}
          />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={processes || []}
              optionLabel="processName"
              value={processes?.filter(({ id }) => process?.map(({ id }) => id)?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
              onChange={handleChangeProcess}
              label={t("processes")}
              handleDisplayedOptionLabel={handleDisplayedOptionLabel}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
            multiple
            options={status || []}
            optionLabel="statusLabel"
            value={status?.filter(({ id }) => statusVar?.map(({ id }) => id)?.includes(id))}
            onChange={handleChangeStatus}
            label={t("Status")}
          />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={resources || []}
              optionLabel="name"
              value={resources?.filter(({ id }) => resource?.map(({ id }) => id)?.includes(id))}
              onChange={handleChangeResource}
              label={t("restriction.form.resources")}
              handleDisplayedOptionLabel={handleDisplayedOptionLabel}
              translateLabel={false}
              keepCaseLabel
          />
          </FormControl>
        </Grid>
        {!defaultFilter && (
        <>
          <Grid item xs={2}>
            <CustomSelectField
                key={sort}
                options={columns || []}
                value={columns.find(({ label }) => label === sort?.label)}
                onChange={(e) => handleChangeSort(e.target.value)}
                variant="standard"
                label={t("Sort By")}
                customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                isCustom
            />
          </Grid>
          {isInstanceOwner && (
            <Grid item xs={2} className={classes.groupContainer}>
              <FormControl fullWidth>
                <CustomAutoComplete
                    multiple
                    options={fleets || []}
                    optionLabel="companyName"
                    value={fleets?.filter(({ id }) => selectedFleet?.map(({ id }) => id)?.includes(id))}
                    noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                    onChange={handleChangeFleet}
                    label={t("groups")}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={2}>
            <FormControl fullWidth>
              <CustomAutoComplete
                multiple
                options={triggerItems}
                optionLabel="label"
                value={triggerItems?.filter(({ value }) => trigger?.includes(value))}
                onChange={handleChangeTrigger}
                label={t("Trigger")}
              />
            </FormControl>
          </Grid>
          <DateFilter
            onFromDateChange={onFromDateChange}
            fromDate={fromSelectedDate}
            onToDateChange={onToDateChange}
            toDate={toSelectedDate}
            selectedDuration={selectedDurationValue}
            onSelectDurationValue={onSelectDurationValue}
            showCustomDate={showCustomDate}
            handleSelectPeriodChange={(event) => onSelectDurationValue(event.target.value)}
            dateFormat={formatDatePickerByLanguage()}
            invalidEndDate={invalidEndDate}
            invalidStartDate={invalidStartDate}
            futureEndDate={futureEndDate}
            futureStartDate={futureStartDate}
            xs={2}
            isCustom
          />
        </>
      )}
        <Grid item container xs={defaultFilter || showCustomDate ? 2 : 6} justify="flex-end">
          <Grid item>
            <ShowMoreFilters
            handleShowMore={() => setDefaultFilter((prevState) => !prevState)}
            showAll={!defaultFilter}
          />
          </Grid>
          <Grid item>
            <ClearFilter clearFilter={clearFilter} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default connect(null, {})(Filter);
Filter.defaultProps = {
  isDefault: false
}
