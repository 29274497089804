import React from "react";
import { useTranslation } from "react-i18next";
import {
  formatDateByLanguage,
} from "util";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import { secondsToTime } from "util/index";

const ExecutionRequestRow = ({
  row,
  isHistorical,
  processName,
  processIcon,
}) => {
  const { t } = useTranslation()
  const getDateDifference = (s) => {
    if (!s) return secondsToTime(0, t);
    return secondsToTime(s, t);
  };
    return (
      <>
        <CustomTableCell width={250}>
          <ProcessIconName
            imgSrc={processIcon}
            processName={processName}
          />
        </CustomTableCell>
        <CustomTableCell text={(row?.priority || row?.priority === 0) ? row?.priority : "---"} />
        <CustomTableCell
          text={
            row?.requestDate
              ? formatDateByLanguage(row?.requestDate)
              : "---"
          }
        />
        <CustomTableCell text={getDateDifference(row?.estimatedDuration)} />
        <CustomTableCell text={getDateDifference(row?.maxWaitTime)} />
        <CustomTableCell text={row?.slaName || "---"} />
        <CustomTableCell text={row?.slaTagFilter || "---"} />
        <CustomTableCell text={t(row?.slaResponseTime) || "---"} />
        {isHistorical && (
          <CustomTableCell text={row?.origineOfStop || "---"} />
        )}
        {isHistorical && (
          <CustomTableCell text={row?.archivedAt ? formatDateByLanguage(row?.archivedAt) : "---"} />
        )}
      </>
  );
};

export default ExecutionRequestRow;
